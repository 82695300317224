<template>
  <div>
    <v-card elevation="3" class="ma-4" v-show="show_table">
      <v-card-title>
        Mapped {{ results_rows_clean.length }} out of {{ out_of }} results
      </v-card-title>
      <v-card-actions>
        <v-btn
          @click.prevent="message1"
          elevation="2"
          color="green"
          class="white--text"
        >
          Export to CSV in Days.
        </v-btn>
        <v-btn
          @click.prevent="message1"
          elevation="2"
          color="green"
          class="white--text"
        >
          Export to CSV in Months.
        </v-btn>
        <v-btn @click.prevent="message1" elevation="2" color="secondary">
          Print All
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-data-table
      v-show="show_table"
      @click:row="click_row"
      dense
      :headers="headers"
      :items="results_rows_clean"
      item-key="encrypted_resultsId"
      class="elevation-1"
      singleSelect
      show-expand
    >
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <div style="display: none">{{ headers }}{{ item }}</div>
          <!-- <div>peeps: {{ item.lst_pars_person }}</div> -->
          <!-- <v-data-table>
            :items="item.Lst_pars_person" :headers="headers_inner"
            disable-pagination
          </v-data-table> -->
          <!-- <table class="inner_table"> -->

          <!-- persons -->
          <h4 v-if="item.lst_pars_person.length > 0">Persons</h4>
          <div
            class="d-flex blue lighten-5"
            v-for="perrow in item.lst_pars_person"
            :key="perrow.perS_REL"
          >
            <div class="d-flex label-display_pair big">
              <label class="ml-2 font-weight-bold">Name:</label>
              <article class="mx-1">{{ perrow.nam }}</article>
            </div>
            <div class="d-flex label-display_pair">
              <label class="ml-2 font-weight-bold">INVL:</label>
              <article class="mx-1">{{ perrow.perS_INV }}</article>
            </div>
            <div class="d-flex label-display_pair med">
              <label class="ml-2 font-weight-bold">DOB:</label>
              <article class="mx-1">{{ perrow.dob }}</article>
            </div>
            <div class="d-flex label-display_pair">
              <label class="ml-2 font-weight-bold">Age:</label>
              <article class="mx-1">{{ perrow.age }}</article>
            </div>
            <div class="d-flex label-display_pair">
              <label class="ml-2 font-weight-bold">Sex:</label>
              <article class="mx-1">{{ perrow.sex }}</article>
            </div>
            <div class="d-flex label-display_pair">
              <label class="ml-2 font-weight-bold">Race:</label>
              <article class="mx-1">{{ perrow.rac }}</article>
            </div>
            <div class="d-flex label-display_pair">
              <label class="ml-2 font-weight-bold">Wgt:</label>
              <article class="mx-1">{{ perrow.wgt }}</article>
            </div>
            <div class="d-flex label-display_pair">
              <label class="ml-2 font-weight-bold">Dom Vic:</label>
              <article class="mx-1">{{ perrow.doM_VIOL }}</article>
            </div>
          </div>

          <!-- offenses -->
          <h4 v-if="item.lst_pars_offense.length > 0">Offenses</h4>
          <div
            class="d-flex yellow lighten-5"
            v-for="offrow in item.lst_pars_offense"
            :key="offrow.ofnS_REL"
          >
            <div class="d-flex label-display_pair med">
              <label class="ml-2 font-weight-bold">Offense:</label>
              <article class="mx-1">{{ offrow.offense }}</article>
            </div>
            <div class="d-flex label-display_pair verybig">
              <label class="ml-2 font-weight-bold">Desc:</label>
              <article class="mx-1">{{ offrow.ofF_DESC }}</article>
            </div>
            <div class="d-flex label-display_pair med">
              <label class="ml-2 font-weight-bold">Bias:</label>
              <article class="mx-1">{{ offrow.bias }}</article>
            </div>
          </div>

          <!-- PROPERTY -->
          <h4 v-if="item.lst_pars_property.length > 0">Property</h4>
          <div
            class="d-flex purple lighten-5"
            v-for="proprow in item.lst_pars_property"
            :key="proprow.parT_REL"
          >
            <div class="d-flex label-display_pair small">
              <label class="ml-2 font-weight-bold">Type:</label>
              <article class="mx-1">{{ proprow.proP_TYP }}</article>
            </div>
            <div class="d-flex label-display_pair small">
              <label class="ml-2 font-weight-bold">Cat:</label>
              <article class="mx-1">{{ proprow.cat }}</article>
            </div>
            <div class="d-flex label-display_pair verybig">
              <label class="ml-2 font-weight-bold">Desc:</label>
              <article class="mx-1">{{ proprow.descript }}</article>
            </div>
            <div class="d-flex label-display_pair">
              <label class="ml-2 font-weight-bold">INVL:</label>
              <article class="mx-1">{{ proprow.p_INVL }}</article>
            </div>

            <div class="d-flex label-display_pair">
              <label class="ml-2 font-weight-bold">Art:</label>
              <article class="mx-1">{{ proprow.art }}</article>
            </div>

            <div class="d-flex label-display_pair small">
              <label class="ml-2 font-weight-bold">Drug:</label>
              <article class="mx-1">{{ proprow.druG_TYP }}</article>
            </div>
            <div class="d-flex label-display_pair">
              <label class="ml-2 font-weight-bold">Meas:</label>
              <article class="mx-1">{{ proprow.meas }}</article>
            </div>
            <div class="d-flex label-display_pair">
              <label class="ml-2 font-weight-bold">Qty:</label>
              <article class="mx-1">{{ proprow.qty }}</article>
            </div>
          </div>

          <h4 v-if="item.lst_pars_narr.length > 0">Narrative</h4>
          <div
            class="d-flex cyan lighten-5"
            v-for="narrow in item.lst_pars_narr"
            :key="narrow.narR_REL"
          >
            <div class="d-flex label-display_pair allwidth">
              <label class="ml-2 font-weight-bold">Desc:</label>
              <article class="mx-1" v-html="narrow.narr"></article>
            </div>
          </div>
        </td>
      </template>
    </v-data-table>

    <div>
      <v-card elevation="3" class="mt-4">
        <v-card-actions>
          <v-btn
            v-show="!show_table"
            @click.prevent="click_row"
            elevation="2"
            color="secondary"
          >
            Back to Table
          </v-btn>
          <v-btn
            v-show="!show_table"
            @click.prevent="message1"
            elevation="2"
            color="secondary"
          >
            Print
          </v-btn>
        </v-card-actions>
      </v-card>

      <printx v-show="!show_table" @click="click_row"></printx>
    </div>
  </div>
</template>

<style>
.inner_tr {
  background-color: rgb(224, 224, 247);
}
.label-display_pair {
  width: 100px;
}
.label-display_pair.small {
  width: 55px;
}
.label-display_pair.med {
  width: 150px;
}
.label-display_pair.big {
  width: 250px;
}
.label-display_pair.verybig {
  width: 400px;
}

.label-display_pair.allwidth {
  width: 100%;
  max-width: 80vw;
}
/* .inner_table {
  border: 1px solid black;
  border-collapse: collapse;
}
.inner_table,
tr,
td {
  border: 1px solid black;
  border-collapse: collapse;
} */
</style>

<script>
import { bus_common } from "../mixins/bus_common";
const printx = () => import("./printx.vue");
export default {
  name: "results_table",
  mixins: [bus_common],
  props: ["results_rows", "out_of"],
  components: { printx },
  data() {
    return {
      show_table: true,
      //   {
      //     text: "Name",
      //     align: "start",
      //     sortable: true,
      //     value: "nam",
      //   },
      //   {
      //     text: "INVL",
      //     align: "start",
      //     sortable: true,
      //     value: "perS_INV",
      //   },
      //   {
      //     text: "DOB",
      //     align: "start",
      //     sortable: true,
      //     value: "dob",
      //   },
      //   {
      //     text: "Sex",
      //     align: "start",
      //     sortable: true,
      //     value: "sex",
      //   },
      //   {
      //     text: "Race",
      //     align: "start",
      //     sortable: true,
      //     value: "rac",
      //   },
      //   {
      //     text: "Wgt",
      //     align: "start",
      //     sortable: true,
      //     value: "wgt",
      //   },
      //   {
      //     text: "Dom",
      //     align: "start",
      //     sortable: true,
      //     value: "doM_VIOL",
      //   },

      //   //         <td>Name:{{ perrow.nam }}</td>
      //   // <td>INVL:{{ perrow.perS_INV }}</td>
      //   // <td>DOB:{{ perrow.dob | shortDate }}</td>
      //   // <td>Age:{{ perrow.age }}</td>
      //   // <td>Sex:{{ perrow.sex }}</td>
      //   // <td>Race:{{ perrow.rac }}</td>
      //   // <td>Wgt:{{ perrow.wgt }}</td>
      //   // <td>DOM:{{ perrow.doM_VIOL }}</td>
      // ],
      results_rows_clean: [],
      headers: [
        {
          text: "Case #",
          align: "start",
          sortable: true,
          value: "dr",
        },
        //RPT_DATE
        {
          text: "RPT Date",
          align: "start",
          sortable: true,
          value: "rpT_DATE",
        },
        //RPT_TIME
        {
          text: "RPT Time",
          align: "right",
          sortable: true,
          value: "rpT_TIME",
        },
        //NAT_CALL
        {
          text: "Type",
          align: "start",
          sortable: true,
          value: "naT_CALL",
        },
        //LOC
        {
          text: "Address",
          align: "start",
          sortable: true,
          value: "loc",
        },
        {
          text: "Zip",
          align: "start",
          sortable: true,
          value: "zip",
        },
      ],
    };
  },
  filters: {},
  watch: {
    results_rows: function () {
      if (this.results_rows != null) {
        this.init(this);
      }
    },
    results_rows_clean: function () {
      if (this.results_rows_clean != null) {
        this.prep_inner_table();
      }
    },
  },
  methods: {
    message1() {
      alert("This button has yet to do anything.");
    },
    click_row() {
      this.show_table = !this.show_table;
    },
    prep_inner_table() {
      //format person(s) for each incidetns
      for (let index = 0; index < this.results_rows_clean.length; index++) {
        var incdnt = this.results_rows_clean[index];
        for (let j = 0; j < incdnt.lst_pars_person.length; j++) {
          const person = incdnt.lst_pars_person[j];
          person.dob = this.format_date_us(person.dob);
          person.nam = person.nam.replace(";", "");
          if (person.doM_VIOL == null) {
            person.doM_VIOL = "N";
          }
        }
        for (let k = 0; k < incdnt.lst_pars_offense.length; k++) {
          let off = incdnt.lst_pars_offense[k];
          if (off.bias == null) {
            off.bias = "None";
          }
          if (parseInt(off.bias) > 11 && parseInt(off.bias) < 85) {
            off.bias = "Yes: " + off.bias;
          }
        }
      }
    },
    init(self) {
      // this.show_loader(true);
      this.results_rows_clean = [];
      for (let index = 0; index < this.results_rows.length; index++) {
        const row = this.results_rows[index];
        let newrow = this.mno(row);
        newrow.rpT_DATE = this.format_date_sql(newrow.rpT_DATE);
        newrow.rpT_TIME = this.format_time_frm_military(newrow.rpT_TIME);

        newrow.dr = this.format_casnum(newrow.dr);
        this.results_rows_clean.push(newrow);
      }
      // this.show_loader(false);
    },
  },
  mounted() {
    this.$nextTick(() => {
      //  this.init(self);
    });
  },
};
</script>
